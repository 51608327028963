<template>
  <section class="container-item">
    <div class="c-title">平台项目</div>
    <div class="c-sub-title">平台生态产品项目。</div>
    <div class="c-content">
      <div class="swiper-container">
        <ul class="team-list swiper-wrapper">
          <li class="team-item swiper-slide">
            <div class="team-info">
              <img class="team-log" src="../../static/gzh.jpg"/>
              <div class="team-name">聆听校园</div>
              <div class="team-desc ellipsis2">聆听校园官方微信公众号。</div>
            </div>
          </li>
          <li class="team-item swiper-slide">
            <div class="team-info">
              <img class="team-log" src="../../static/jiaowu.png"/>
              <div class="team-name">综合教务</div>
              <div class="team-desc ellipsis2">saas版大学生综合教务。查成绩、看课表、校园百科、校园电话、学年校历、校内地图...</div>
            </div>
          </li>
          <li class="team-item swiper-slide">
            <div class="team-info">
              <img class="team-log" src="../../static/lxwm.png"/>
              <div class="team-name">敬请期待</div>
              <div class="team-desc ellipsis2">如果你有好的建议或者反馈欢迎联系我们，我们期待听到您的声音。</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
  <script></script>
  <style scoped>
.container-item {
  padding-top: 60px;
  color: #333;
  overflow: hidden;
  background-color: #3061FF;
  background-image: url('../../static/itemBg2.png');
  background-size: 100%;
}
.container-item .c-title {
  font-size: 54px;
  text-align: center;
  line-height: 75px;
  font-weight: 700;
  color: #fff;
}
.container-item .c-sub-title {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  color: #666;
  color: #fff;
}
.c-content {
  padding-top: 56px;
  padding-bottom: 85px;
}
.swiper-wrapper {
  z-index: 1;
  display: flex;
  justify-content:center;
}
.c-content .team-list .team-item {
  background: #F3F8FE;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  overflow: hidden;
  flex: none;
  transition-property: transform;
  width: 280px;
  margin-right: 55px;
}
.c-content .team-list .team-item .team-info {
  height: 270px;
  padding: 40px 30px 30px;
  justify-content: center;
  align-items: center;
}
.c-content .team-list .team-item .team-info .team-log {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin: auto;
}
.c-content .team-list .team-item .team-info .team-name {
  font-size: 24px;
  text-align: center;
  margin-top: 12px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.c-content .team-list .team-item .team-info .team-desc {
  height: 80px;
  font-size: 14px;
  color: #8b8f9f;
  line-height: 24px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
.ellipsis2 {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>