<template>
  <div id="app">
    <div class="header-bar" :class="{ white: ismusk }">
      <img :src="logoImg" class="logo-img" />
      <div class="menu-bar">
        <div class="menu-item" data-target="banner">首页</div>
        <div class="menu-item" data-target="gift" @click="info">API文档</div>
        <div class="menu-item" data-target="activity" @click="toConsole">
          管理后台
        </div>
      </div>
    </div>
    <homePage />
    <item1Page v-if="false" />
    <item2Page />
    <item3Page />
    <item4Page />
    <footerPage />
    <n-message-provider>
      <n-dialog-provider>
        <MessageApi />
      </n-dialog-provider>
    </n-message-provider>
  </div>
</template>

<script>
import homePage from "./components/home.vue";
import item1Page from "./components/item1.vue";
import item2Page from "./components/item2.vue";
import item3Page from "./components/item3.vue";
import item4Page from "./components/item4.vue";
import footerPage from "./components/footer.vue";
import MessageApi from "@/components/MessageApi.vue";
import { NMessageProvider, NDialogProvider, NIcon } from "naive-ui";
import { ref } from "vue";
export default {
  components: {
    homePage,
    MessageApi,
    NMessageProvider,
    NDialogProvider,
    footerPage,
    item1Page,
    item2Page,
    item3Page,
    item4Page,
  },
  setup() {
    const ismusk = ref(false);
    const logoImg = ref(require("../static/logo.png"));
    window.onscroll = function (e) {
      var scrollPosition = window.scrollY;
      if (scrollPosition > 700) {
        ismusk.value = true;
        logoImg.value = require("../static/logo2.png");
      } else {
        ismusk.value = false;
        logoImg.value = require("../static/logo.png");
      }
    };
    const info = () => {
      window.$message.info("暂未开放，敬请期待。", {
        keepAliveOnHover: true,
      });
    };
    const toConsole = () => {
      window.open("https://console.listenfuture.cn", "_blank");
    };
    return {
      info,
      toConsole,
      ismusk,
      logoImg,
    };
  },
};
</script>

<style scoped>
.header-bar {
  position: fixed;
  background: transparent;
  border-bottom: 1px solid transparent;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
}
.header-bar .menu-bar .menu-mark {
  position: absolute;
  top: 30px;
  transform: translateX(-50%);
  height: 3px;
  width: 35px;
  border-radius: 5px;
  background: #fff;
  transition: left 0.3s;
}
.header-bar.white {
  background: #fff;
  border-bottom: 1px solid #c7c7c7;
  color: #333;
}
.logo-img {
  width: 150px;
}
.header-bar .menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.header-bar .menu-bar .menu-item {
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  width: 100px;
}
</style>
