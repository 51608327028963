<template>
    <section class="container-item">
      <div class="c-title">敬请期待~</div>
      <div class="c-sub-title">其他板块正在增加中，敬请期待～</div>
    </section>
  </template>
  <script setup>
  </script>
    <style scoped>
  .container-item {
    padding-top: 60px;
    color: #333;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
    background-size: 100%;
    background: #fff;
    padding-bottom: 50px;
  }
  .container-item .c-title {
    font-size: 45px;
    text-align: center;
    line-height: 75px;
    font-weight: 700;
  }
  .container-item .c-sub-title {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    color: #666;
  }
  </style>