<template>
  <div class="viewPage">
    <div>本站素材来源：百度百科、四川大学宣传片、南京大学宣传片。苏ICP备2022047661号-1</div>
    <div>
      Copyright © 2021 - 2023 ListenFuture. All Rights Reserved.
      南京聆听未来科技有限公司 版权所有
    </div>
  </div>
</template>
<script></script>
<style scoped>
.viewPage {
  width: 100%;
  height: 15vh;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  color: #888;
  font-weight: 400;
  border-top: solid 1px #f1f1f1;
}
</style>