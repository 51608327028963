<template>
  <section class="container-item" id="action">
    <div class="c-title">校园合伙人</div>
    <div class="c-sub-title">成为校园合伙人，点燃青春。</div>
    <div class="c-content">
      <ul class="gift-list">
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-8_306d9b5.svg"
            class="gift-icon"
          />
          <div class="gift-name">专属周边</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-7_6a10aea.svg"
            class="gift-icon"
          />
          <div class="gift-name">实践证明</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-4_1301776.svg"
            class="gift-icon"
          />
          <div class="gift-name">大使证书</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-3_47ab7e6.svg"
            class="gift-icon"
          />
          <div class="gift-name">夏令营</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-1_b3e9671.svg"
            class="gift-icon"
          />
          <div class="gift-name">人际交往</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-5_87b6bb3.svg"
            class="gift-icon"
          />
          <div class="gift-name">组织能力</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-9_f5124ed.svg"
            class="gift-icon"
          />
          <div class="gift-name">职业规划</div>
        </li>
        <li class="gift-item">
          <img
            src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/gift/imgs/gift-icon-2_6ea65fc.svg"
            class="gift-icon"
          />
          <div class="gift-name">大厂实习</div>
        </li>
      </ul>
    </div>
  </section>
</template>
<script></script>
<style scoped>
.container-item {
  padding-top: 90px;
  color: #333;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  background-image: url('../../static/itemBg2.png');
  background-size: 100%;
}
.container-item .c-title {
  font-size: 54px;
  text-align: center;
  line-height: 75px;
  font-weight: 700;
}
.container-item .c-sub-title {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  color: #666;
}
.c-content {
  padding-top: 56px;
  padding-bottom: 105px;
  position: relative;
  width: 1180px;
  margin: auto;
}
.gift-list {
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gift-item {
  width: 150px;
  height: 150px;
  margin: 0 25px 30px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>