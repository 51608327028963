<template>
  <div class="container">
    <section
      class="banner fixed"
      id="banner"
    >
      <div class="video-container">
        <video
          muted=""
          loop="loop"
          autoplay="autoplay"
          src="../../static/home.mp4"
          class="video"
          id="video-home"
          data-object-fit=""
        >
          抱歉，您的浏览器不支持内嵌视频
        </video>
      </div>
      <div class="content-container">
        <img class="slogan" src="../../static/slogan.png" />
        <div></div>
        <img class="slogan-bt" src="../../static/slogan-bt.png" />
        <ul class="banner-data" v-if="false">
          <li class="data-item">
            <div class="data-value">700人+</div>
            <div class="data-name">校园大使</div>
          </li>
          <li class="data-item">
            <div class="data-value">2000万+</div>
            <div class="data-name">覆盖人数</div>
          </li>
          <li class="data-item">
            <div class="data-value">1425万+</div>
            <div class="data-name">知识贡献</div>
          </li>
          <li class="data-item">
            <div class="data-value">3020场+</div>
            <div class="data-name">公益行动</div>
          </li>
        </ul>
        <div class="banner-btn-box">
          <button
            @click="scrollToElement"
            :class="isHovered ? 'highlight' : 'banner-btn'"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
          >
            走近我们
          </button>
          <a
            href="https://work.weixin.qq.com/kfid/kfca65eee7eda0f5e8e"
            target="_blank"
            ><button
              :class="isHovered2 ? 'highlight' : 'banner-btn'"
              @mouseover="isHovered2 = true"
              @mouseout="isHovered2 = false"
            >
              立即加入
            </button></a
          >
        </div>
      </div>
      <a href="#gift" class="bottom-cursor">
        <img
          src="https://bkssl.bdimg.com/static/wiki-campus/widget/main/banner/imgs/arrow_e96e24a.svg"
          class="bottom-icon"
        />
      </a>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "home-page",
  setup() {
    const isHovered = ref(false);
    const isHovered2 = ref(false)
    function scrollToElement() {
      const element = document.getElementById("action");
      element.scrollIntoView({ behavior: "smooth" });
    }
    return { scrollToElement, isHovered,isHovered2 };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banner {
  background-size: cover;
  overflow: hidden;
  height: calc(100vh);
}
.banner .video-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.banner .video-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 25, 25, 0.4);
}
.banner .video-container .video {
  height: auto;
  min-height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}
.banner .content-container {
  position: relative;
  height: 100%;
  width: 810px;
  margin: auto;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  color: #fff;
}
.banner .content-container .slogan {
  width: 700px;
  display: block;
  margin: 0 auto;
}
.slogan-bt {
  width: 550px;
  display: block;
  margin: 35px auto;
}
.banner .content-container .banner-data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.banner .content-container .banner-data .data-item {
  border-right: 1px solid rgba(238, 238, 238, 0.3);
  width: 20%;
  text-align: center;
  line-height: 1;
}
.banner .content-container .banner-data .data-item .data-value {
  font-size: 30px;
  font-weight: 700;
}
.banner .content-container .banner-data .data-item .data-name {
  font-size: 14px;
  margin-top: 8px;
}
.banner .content-container .banner-btn-box {
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 93px;
}
.banner .content-container .banner-btn-box .banner-btn {
  width: 210px;
  height: 54px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 27px;
  margin: 0 14px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  cursor: pointer;
}
.highlight {
  width: 210px;
  height: 54px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 27px;
  margin: 0 14px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  cursor: pointer;
}
.VideoBg video {
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: hidden;
  transform: translate(-50%, -50%);
}
.bottom-cursor {
  position: absolute;
  z-index: 3;
  bottom: 50px;
  width: 20px;
  height: 34px;
  cursor: pointer;
  left: calc(50% - 10px);
}
.bottom-icon {
  position: absolute;
  animation: cursor 3s infinite;
}
@keyframes cursor {
  0% {
    top: 8px;
    opacity: 0;
  }

  70% {
    top: 16px;
    opacity: 1;
  }

  100% {
    top: 24px;
    opacity: 0;
  }
}
.video-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../static/bg1.png');
  background-size: auto 100%;
}
.tags {
  font-size: 36px;
  position: absolute;
  top: 50%;
  color: #fff;
}
.videoClass {
  width: 100%;
}
.video-container:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 25, 25, 0.3);
}
</style>
