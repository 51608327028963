<template>
  <section class="container-item" id="action">
    <div class="c-title">入驻学校</div>
    <div class="c-sub-title">持续增加中，期待您的加入。</div>
    <div class="c-content">
      <div class="rank-body">
        <ul class="campus-list" style="display: flex">
          <li
            class="campus-item"
            v-for="(item, index) in schoolList"
            :key="index"
          >
            <img
              :src="
                'https://listenfuture.oss-cn-nanjing.aliyuncs.com/listen-school/logo/' +
                item.id +
                '.jpg?x-oss-process=image/resize,m_lfit,h_450,w_300'
              "
              class="campus-badge"
              :title="item.name"
            />
            <div class="campus-name">{{ item.name }}</div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script setup>
const schoolList = [
  {
    name: "南京工业大学",
    id: 107,
  },
  {
    name: "南京传媒学院",
    id: 2543,
  },
  {
    name: "安徽师范大学",
    id: 314,
  },
  {
    name: "河北科技大学",
    id: 55,
  },
  {
    name: "南通理工学院",
    id: 1544,
  },
  {
    name: "合肥师范学院",
    id: 1801,
  },
  {
    name: "三江学院",
    id: 1179,
  },
  {
    name: "南京信息职业技术学院",
    id: 1376,
  },
  {
    name: "南京航空航天大学金城学院",
    id: 2542,
  },
  {
    name: "泰州科技学院",
    id: 1143,
  },
];
</script>
  <style scoped>
.container-item {
  padding-top: 60px;
  color: #333;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  background-image: url("../../static/itemBg1.png");
  background-size: 100%;
  background: #f0f5fb;
}
.container-item .c-title {
  font-size: 54px;
  text-align: center;
  line-height: 75px;
  font-weight: 700;
}
.container-item .c-sub-title {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  color: #666;
}
.c-content {
  padding-top: 26px;
  padding-bottom: 25px;
  width: 100%;
}
.campus-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.campus-list .campus-item {
  position: relative;
  width: 210px;
  height: 240px;
  flex: none;
  margin-top: 15px;
  margin-bottom: 35px;
  border-radius: 9px;
  overflow: hidden;
  background: #fff;
  padding: 27px 15px;
  cursor: pointer;
  margin-right: 30px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
.campus-list .campus-item .campus-badge {
  width: 160px;
  height: 160px;
  display: block;
  margin: auto;
  border-radius: 50%;
}
.campus-list .campus-item .campus-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 17px;
  line-height: 1;
  margin-top: 25px;
  text-align: center;
}
</style>